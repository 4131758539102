module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-toc/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"http://localhost:2368","contentApiKey":"93a54c03edb52384f45e5ba3c7"},"production":{"apiUrl":"http://localhost:2368","contentApiKey":"93a54c03edb52384f45e5ba3c7"}},"siteConfig":{"siteUrl":"https://amaljith.me","infiniteScroll":true,"postsPerPage":3,"siteTitleMeta":"Amaljith","siteDescriptionMeta":"Amaljith","shortTitle":"Amaljith","siteIcon":"favicon.png","backgroundColor":"#e9e9e9","themeColor":"#15171A","verbose":false,"severity":"info"},"mediaConfig":{"gatsbyImages":true,"gatsbyImageLoading":"lazy","gatsbyImageFadeIn":true},"routes":{"basePath":"/","collections":[]}},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":false,"overrideOS":false},
    }]
